function initHeros(){
    "use strict";
    var heros_carousel = $('.heros-carousel').slick({
        dots        : $('.heros-carousel').data('dots'),
        arrows      : false,
        infinite    : true,
        speed       : 300,
        fade        : true,
        cssEase     : 'linear'
    });
    heros_carousel.on('afterChange', function(slick, currentSlide){
        $('.hero-carousel-controls .slider-counter span').html(Number($('.heros .item.slick-current.slick-active').data('slick-index'))+1);
    });
    $('.hero-carousel-controls .prev').click(function(){
        heros_carousel.slick('slickPrev');
        return false;
    });
    $('.hero-carousel-controls .next').click(function(){
        heros_carousel.slick('slickNext');
        return false;
    });

    $('.scroll-btn').click(function(){
        $('body,html').animate({scrollTop:$(window).innerHeight()}, '1000');
        return false;
    });
	
	
	$('.anchor-link').click(function(){
		$('body,html').animate({scrollTop:$(window).innerHeight() - $('header').innerHeight()}, '1000');
		return false;
	});

    if ($('.factories-carousel .secondary-img').length > 1 ) {
        $('.factories-carousel').slick({

            arrows      : false,
            dots        : true,
            infinite    : true,
            speed       : 300,
            fade        : true,
            cssEase     : 'linear'

        });
    }else{
        $('.factories-carousel').slick({

            arrows      : false,
            dots:        false,
            infinite    : true,
            speed       : 300,
            fade        : true,
            cssEase     : 'linear'

        });
    }
}

