$(function(){
    callbacks('#wrap');
});

$(window).load( function(){
  
    if (window.location.hash) {
      var target = location.hash.split('#');
      setTimeout(function() {
        window.scrollTo(0, 0);
      }, 1);
    }
   if (window.location.hash) {
      var anchorpage = location.hash.split('#');
      setTimeout(function () {
        goToAnchorPage(anchorpage[1]);
      }, 500);
    }
    
});

function callbacks(c) {
    var container = c || '';
    //ajaxContent(container + ' .ajax-content');
    $(container + ' .callbacks').each(function () {
        if(!$(this).hasClass('callbacks-executed')){
            if(container !=='' || !$(this).hasClass('callbacks-executed')){
                var c = $(this).data();
                for (var i in c) {
                    if (i.indexOf('fn') == 0 && c[i] != '') {
                        var fn = c[i];
                        var params = c[ 'params' + i ] || null;

                        $(this).addClass('callbacks-executed');

                        try {
                            if (c[ 'after' + i ] && typeof(window[c[ 'after' + i ]]) === 'function') {

                                var callbackAfter = c[ 'after' + i ];
                                var callbackAfterParams = c[ 'after' + i + 'Params' ] || null;

                                window[ fn ](params, function(){
                                    window[callbackAfter](callbackAfterParams);
                                });
                            } else {
                                window[ fn ](params);
                            }
                        } catch (Error) {
                            console.log(Error + ' '+fn);
                        }
                    }
                }
            }
        }
    });
}


function goToAnchorPage(anchor){
    $('html, body').animate({
        'scrollTop':   $('#'+anchor).offset().top - 200
    }, 500);
}
