/**
 * Created by 2YD on 9/20/17.
 */
$(function(){

    $(document).on('scroll',function() {
        "use strict";
        var _scrollTop = $(window).scrollTop();
        var _hero;

        _hero = 200;
        var _header = $('header').innerHeight();
        if( Number(_header)+Number(_scrollTop) > Number(_hero) ){
            $('header').addClass('bg');
        }else{
            $('header').removeClass('bg');
        }
    });

});