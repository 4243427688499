
$(function(){
	$body = $('body');
	$body.append('<div id="modal" class="modal fade"><div class="modal-content"></div></div>');
});


$(document).on('click', ".ajax-modal", function (e) {
		
        try{
            if(typeof ($(this).attr("href"))!=="undefined"){
				openAjaxModal($(this).attr('href'), $('#'+ $(this).data('modal')));				
			}else{
				openAjaxModal($(this).data('href'), $('#'+ $(this).data('modal')));
			}
			e.preventDefault();
        }catch(Error){
            console.log(Error);
        }

        return false;
    });

function openAjaxModal(href,$mm){

     $m = $mm || $modal;

    $m.removeData('bs.modal');
    $m.empty();
    $m.load(href,function(){
        $m.modal('show');
        //skinFormElements('#'+$m.attr('id'));
        callbacks('#'+$m.attr('id'));
    });

}

function initShares(){
	"use strict";
	$('.close-share-this').click(function(){
		$('.modal.in').modal('hide');
		return false;
	});

    $('.clipboardButton').html('<i class="fa fa-copy"></i>  Copy Link');


    var clipboard = new Clipboard('.clipboardButton');
	clipboard.on('success', function(e) {
		console.info('Action:', e.action);
		console.info('Text:', e.text);
		console.info('Trigger:', e.trigger);
		
		$('.clipboardButton').html('<i class="fa fa-check"></i>  Copied');
		
		e.clearSelection();
	});
	clipboard.on('error', function(e) {
		console.error('Action:', e.action);
		console.error('Trigger:', e.trigger);
		
	});	
}